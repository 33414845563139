<template>
	<div
		class="position-fixed-full flex-column"
		style="z-index: 999"
	>
		<div class="full-height flex-column ">
			<div class="header">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="goBack"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<h2 class="page_tit">{{ program.title }}</h2>
				</div>
				<div
					class="save"
				>
					<button
						@click="postSave"
						class="btn_save"
					>저장</button>
				</div>
			</div>
			<div
				class="full-height bg-white mt-50 overflow-y-auto"
			>

				<div class=" pa-10">

					<div class="input_area">
						<div class="bright_input justify-space-between items-center gap-10">
							<label class="square-64 radius-10 mb-0">
								<img
									v-if="item_info.optiop_thumbnail_img.optiop_img_partial_list.length > 0"
									:src="item_info.optiop_thumbnail_img.optiop_img_partial_list[0].optiop_thumbnail_img_url"
									@error="$bus.$emit('onErrorImage', $event)"
									class="object-cover"
									/>
								<img
									v-else-if="item.optiop_img_url"
									:src="item.optiop_img_url"
									@error="$bus.$emit('onErrorImage', $event)"
									class="object-cover"
								/>
								<img
									v-else
									:src="require('@/assets/image/icon_camera_gray.svg')"
								/>
								<input-file-new accept="image/*" @change="setImg($event)" />
							</label>
							<div class="flex-1">
								<input
									v-model="item.optiop_title"
									type="text" id="cartelDesc" :placeholder="$language.voting.txt_voting_add" maxlength="20"
									class="width-100"
								> <!-- 투표 제목 -->
							</div>
						</div>
					</div>
				</div>

				<div class="pa-10">
					<div class="subindex_item">
						<div class="input_area ">
							<div class="bright_input justify-space-between items-center mt-10">
								<label class="tit mt-10">{{  $language.voting.title_voting_add_contents }} <!-- 상세 내용 --></label>
								<p class="toggle-group h_unset ">
									<span
										class="btn-toggle " :class="{ on: item.optiop_info_use_fg == 'Y'}"
										@click="item.optiop_info_use_fg = item.optiop_info_use_fg == 'Y' ? 'N' : 'Y' "
									></span>
								</p>
							</div>
							<p class="input_desc pb-0">{{ $language.voting.txt_voting_add_contents }} <!-- 투표 항목의 상세내용 및 동영상등을 첨부할 수 있습니다 --> </p>
						</div>
					</div>

					<div
						v-if="item.optiop_info_use_fg == 'Y'"
						class="mt-10 top-line pt-10"
					>
						<div class="input_area ">
							<div class="bright_input justify-space-between items-center">
								<label class="tit">{{  $language.voting.title_voting_add_file }} <!-- 첨부파일 상세 --></label>
							</div>
							<p class="input_desc pb-0">{{ $language.voting.txt_voting_add_file }} <!-- 첨부파일은 하나만 등록가능합니다 --> </p>
						</div>
						<div class="justify-space-between mt-10 gap-10">
							<label
								class="btn_m btn_outline_blue width-100"
							>{{ $language.voting.btn_voting_add_file_img }} <!-- 사진 첨부 --> <input-file-new accept="image/*" @change="setFile('CA03900001', $event)" ref="file_image" /></label>
							<button
								@click="onLink"
								class="btn_m btn_outline_blue width-100"
							>{{ $language.voting.btn_voting_add_link }} <!-- 동영상 링크 --></button>
						</div>

						<div
							v-if="item.appendix_url || item.optiop_file_thumbnail_img.optiop_img_partial_list.length > 0"
							class="mt-10 overflow-y-auto "
						>
							<div
								v-if="item.optiop_appendix_file_code == 'CA03900001'"
								class="box position-relative radius-20"
							>
								<img
									v-if="item.appendix_url"
									:src="item.appendix_url"
									@error="$bus.$emit('onErrorImage', $event)"
									class="object-cover "
								/>
								<template
									v-else
								>
									<img
										v-for="(img, i_index) in item.optiop_file_thumbnail_img.optiop_img_partial_list"
										:key="'img_' + i_index"
										:src="img.optiop_file_thumbnail_img_url"
									/>
								</template>
							</div>

							<video-embed
								v-else-if="item.optiop_appendix_file_code == 'CA03900003'"
								:src="item.appendix_url"
							></video-embed>

							<button
								class=" position-absolute bg-white radius-100"
								style="right: 10px; top: 10px"
								@click="item.appendix_url = ''"
							><v-icon class="color-black">mdi-close-circle</v-icon></button>
						</div>
					</div>

					<div class="mt-20">
						<div class="bright_input justify-space-between items-center">
							<label class="tit">{{  $language.voting.title_voting_add_contents }} <!-- 상세 내용 --></label>
						</div>
						<div class="mt-10">
							<textarea
								v-model="item.optiop_contents"
								rows="10"
								class="box width-100 radius-20 size-px-14 pa-20"
								:placeholder="$language.voting.txt_voting_contents"
							></textarea> <!-- 내용을 입력하세요 -->
						</div>
					</div>

					<div
						v-if="item.optiop_info_use_fg == 'Y'"
						class="mt-20"
					>
						<div class="bright_input justify-space-between items-center">
							<label class="tit">{{  $language.voting.title_voting_social_url }} <!-- 소셜 URL --></label>
						</div>
						<div class="mt-10">
							<ul>
								<li
									v-for="(social, s_index) in optiop_social_request_list"
									:key="'social_' + s_index"
									class="justify-space-between gap-10 li-social mb-10 items-center"
								>
									<div class="bg-gray radius-20 square-38 flex-column justify-center items-center">
										<label
											@click="onSocial(social)"
										>
											<img
												v-if="social.thumbnail_img_url || social.system_img_url"
												:src="social.thumbnail_img_url ? social.thumbnail_img_url : social.system_img_url"
												@error="$bus.$emit('onErrorImage', $event)"
												class="object-cover"
											/>
											<div v-else class="bg-gray-light radius-100 font-weight-600 color-gray size-px-9">SOCIAL</div>
										</label>
									</div>
									<div class="bright_input justify-space-between items-center flex-1 ">
										<input type="text" v-model="social.lnk_url" :placeholder="$language.voting.txt_voting_social_url" />
										<button
											class="btn-delete pa-10"
											@click="removeSocial(s_index)"
										><img :src="require('@/assets/image/icon_delete_blue.svg')" /></button>
									</div>
								</li>
							</ul>
							<div class="text-right">
								<button
									class="btn-add pa-10"
									@click="addSocial()"
								><img :src="require('@/assets/image/icon_add_blue.svg')" /></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<PopupLayer
			v-if="is_on_link"
		>
			<template
				v-slot:body
			>
				<div class="bg-white  radius-20 overflow-hidden">
					<div class="pa-20">
						<h3 class="pop_tit pop_tit_logo text-center under-line mt-30 pb-30">{{ $language.voting.title_voting_add_link}} <!-- 동영상 URL 입력 --></h3>
					</div>
					<div class="input_area prl-20 ">
						<div class="bright_input ">
							<input
								v-model="appendix_url"
								type="text" :placeholder="$language.voting.txt_voting_add_link"
								class="width-100"
							>
						</div>
					</div>
					<div
						class="btn_wrap mt-20 justify-space-between"
					>
						<button
							@click="cancel"
							class="pop_btn btn_fill_gray"
						>{{  $language.common.cancel }}</button>
						<button
							@click="setLink"
							class="pop_btn btn_fill_blue"
						>{{  $language.common.ok }}</button>
					</div>
				</div>
			</template>
		</PopupLayer>

		<PopupLayer
			v-if="is_on_social"
		>
			<template
				v-slot:body
			>
				<div class="bg-white  radius-20 overflow-hidden">
					<div class="pa-20">
						<h3 class="pop_tit  text-center under-line mt-30 pb-30">{{ $language.voting.title_voting_social_select}} <!-- 소셜 아이콘 선택 --></h3>
					</div>
					<div class="justify-start gap-10 pa-20 flex-wrap">
						<div
							v-for="(social, s_index) in items_social"
							:key="'social_' + social + s_index"
							@click="setSocial(social)"
						>
							<img :src="social.thumbnail_img_url" @error="$bus.$emit('onErrorImage')" />
						</div>
					</div>
				</div>
			</template>
		</PopupLayer>
	</div>

</template>

<script>
	import InputFileNew from "@/components/InputFileNew";
	import PopupLayer from "@/view/Layout/PopupLayer";
	export default {
		name: 'CartelVotingEditItem'
		, props: ['user', 'item_info', 'edit_info']
		, components: {PopupLayer, InputFileNew}
		, data: function(){
			return {
				program: {
					title: this.item_info.cartl_vote_optiop_number ? this.$language.voting.title_voting_item_modify : this.$language.voting.title_voting_item_add
					, name: this.item_info.cartl_vote_optiop_number ? this.$language.voting.title_voting_item_modify : this.$language.voting.title_voting_item_add
					, type: 'cartel_sub'
					, not_header: true
					, not_footer: true
				}
				, item: {
					optiop_info_use_fg: 'N'
					, optiop_appendix_file_code: ''
					, appendix_url: ''
					, optiop_contents: ''
					, optiop_social_request_list: [
					]
					, optiop_file_thumbnail_img: {
						optiop_img_partial_list: []
					}
				}
				, is_on_link: false
				, appendix_url: ''
				, items_social: [
					'band', 'facebook', 'github', 'instagram', 'line', 'link', 'telegram', 'tik-tok', 'twitch', 'twitter', 'youtube'
				]
				, max: 10
				, is_on_social: false
				, item_social: {

				}
				, optiop_social_request_list: []
			}
		}
		, methods: {
			goBack: function(){
				this.$emit('cancel')
			}
			, postSave: async function(){
				try {
					this.$bus.$emit('on', true)

					let url = this.$api_url.api_path.post_voting_bbs_items_save
					if(this.item_info.cartl_vote_optiop_number){
						url = this.$api_url.api_path.put_voting_bbs_temp_items
					}
					let d = []
					this.optiop_social_request_list.filter(( item) => {
						delete item.thumbnail_img_url
						d.push(item)
					})
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
							, cartl_vote_number: this.$route.params.e_id
							, optiop_img_url: this.item.optiop_img_url // 선택지이미지url", ->omitempty
							, optiop_title: this.item.optiop_title // 선택지제목", ->omitempty
							, optiop_info_use_fg: this.item.optiop_info_use_fg // 선택지정보사용여부", ->required
							, optiop_contents: this.item.optiop_contents // 선택지내용", ->omitempty
							, optiop_appendix_file_code: this.item.optiop_appendix_file_code // 선택지첨부파일코드", ->omitempty
							, appendix_url: this.item.appendix_url // 첨부url", ->omitempty
							, optiop_social_request_list: d
							, cartl_vote_optiop_number: this.item_info.cartl_vote_optiop_number

						}
						, type: true
					})
					if (result.success) {
						this.$emit('click')
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, getData: async function(){
				try {
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_voting_bbs_items
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
							, cartl_vote_number: this.$route.params.e_id
							, cartl_vote_optiop_number: this.item_info.cartl_vote_optiop_number

						}
						, type: true
					})
					if (result.success) {
						this.item = result.data

						this.$set(this.item, 'optiop_info_use_fg', result.data.optiop_contents_use_fg ? result.data.optiop_contents_use_fg : 'N')
						this.$set(this.item, 'optiop_appendix_file_code', result.data.optiop_appendix_file_code)
						this.$set(this.item, 'appendix_url', result.data.optiop_file_url)
						this.$set(this.item, 'optiop_img_url', '')
						this.$set(this.item, 'optiop_social_request_list', result.data.optiop_social_info_list)
						this.$set(this.item, 'optiop_file_thumbnail_img', result.data.optiop_file_thumbnail_img)
						this.$set(this, 'optiop_social_request_list', result.data.optiop_social_info_list)

						if(result.data.optiop_social_info_list.length < 1){
							this.item.optiop_social_request_list = []
						}
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, setFile: function(type, e){
				this.item.optiop_appendix_file_code = type
				console.log(e)
				this.$set(this.item, 'appendix_url', e)
				console.log(this.item)
				setTimeout(() => {
					// this.$set(this.item, 'appendix_url', e)
				}, 100)

			}
			, setImg: function(e){
				this.$set(this.item, 'optiop_img_url', e)
			}
			, onLink: function(){
				this.is_on_link = true
			}
			, cancel: function (){
				this.is_on_link = false
			}
			, setLink: function (){
				this.item.optiop_appendix_file_code = 'CA03900003'
				this.item.appendix_url = this.appendix_url
				this.is_on_link = false
			}
			, addSocial: function(){
				if(this.optiop_social_request_list.length < this.max){
					this.optiop_social_request_list.push({ system_img_number: '', lnk_url: ''})
				}else{
					this.$bus.$emit('notify', { type: 'error', message: this.$language.voting.txt_voting_social_max})
				}
			}
			, removeSocial: function(index){
				if(this.optiop_social_request_list.length > 1){
					this.optiop_social_request_list.splice(index, 1)
				}else{
					this.optiop_social_request_list = []
				}
			}
			, onSocial: function(item){
				this.item_social = item
				this.is_on_social = true
			}
			, setSocial: function(social){
				this.item_social.system_img_number = social.system_img_number
				this.item_social.thumbnail_img_url = social.thumbnail_img_url
				this.is_on_social = false
			}
			, getSystemImage: async function(){
				try {
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_system_image
						, data: {
							member_number: this.user.member_number
							, system_img_code: 'CM01300001'
							, img_resolu_code: 'CM00300060'

						}
						, type: true
					})
					if (result.success) {
						this.items_social = result.data.system_img_list
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			console.log(this.item_info)
			this.getSystemImage()
			if(this.item_info.cartl_vote_optiop_number){
				this.getData()
			}
		}
	}
</script>

<style>
	.li-social:last-child .btn-delete{

	}
	.li-social:not(:last-child) .btn-add{

	}

	iframe {
		width: 100%;
		border: none;
		height: 300px;
	}
</style>