<template>
	<div class="full-height flex-column justify-space-between">
		<div class="">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="prev"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">{{ program.title }}</h2>
			</div>
			<div
				class="save"
			>
				<button
					@click="postTemp"
					class="btn_save mr-10"
					:disabled="is_next"
				>{{ $language.voting.title_voting_temp}} <!-- 임시저장 --></button>
				<button
					@click="postSave"
					class="btn_save"
					:disabled="is_next"
				>{{ $language.common.complete }} <!-- 완료 --></button>
			</div>
		</div>

		<div class="full-height overflow-y-auto">
			<div
				v-if="items.length > 0"
				class=""
			>
				<ul
				>
					<draggable
						v-model="items"
						@start="setAction"
						@end="is_action = ''"
						handle=".handle"
					>
						<li
							v-for="(item, index) in items"
							:key="'item_' + index"
						>
							<div
								class="justify-space-between items-center gap-10 pa-10 under-line"
							>
								<div class="pa-5 " :class="{handle: is_handle}"><img :src="require('@/assets/image/icon_menu.svg')" /></div>
								<div
									class="justify-space-between items-center flex-1 gap-10"
								>
									<div class="square-64 radius-10" style="background-color: #eee">
										<img
											v-for="(thumb, t_index) in item.optiop_thumbnail_img.optiop_img_partial_list"
											:key="'thumb_' + t_index"
											:src="thumb.optiop_thumbnail_img_url"
											@error="$bus.$emit('onErrorImage', $event)" class="object-cover"
										/>
									</div>
									<div class="text-left size-px-14 flex-1">{{ item.optiop_title }}</div>
								</div>
								<div class="pa-5" @click="onMore(item)"><img :src="require('@/assets/image/icon_dot_vertical.svg')" /></div>
							</div>
						</li>
					</draggable>
				</ul>
			</div>

			<Empty
				v-else
				class="full-height"
				text="투표항목을 추가하세요. <br/>투표항목은 2가지 이상일경우 투표 등록이 가능합니다"
			></Empty>
		</div>
		<div
			class="pa-10 mt-auto"
		>
			<button
				class="btn_l btn_fill_blue width-100"
				@click="toItem"
			>{{ $language.voting.title_voting_add}} <!-- 투표항목 추가 --></button>
		</div>

		<CartelVotingEditItem
			v-if="is_on_item"
			:user="user"
			:edit_info="edit_info"
			:item_info="item"

			@click="completeMore"
			@cancel="clearItem"
		></CartelVotingEditItem>

		<PopupHalf
			v-if="is_on_more"
			:user="user"
			:title="title_half"

			@cancel="offMore"
		>
			<template
				v-slot:half_items
			>
				<li class="pb-10" @click="toEdit">{{ $language.voting.sub_voting_item_modify}} <!-- 투표 항목 수정 --></li>
				<li @click="onDelete">{{ $language.voting.sub_voting_item_delete}} <!-- 투표 항목 삭제 --></li>
			</template>
		</PopupHalf>

		<Popup_confirm
			v-if="is_on_delete"

			@cancel="offDelete"
			@click="postDelete"
		>
			<template v-slot:title>{{ $language.voting.title_voting_item_delete }}</template>
			<template
				v-slot:main-txt
			>
				<div :inner-html.prop="$language.voting.txt_voting_item_delete | nl2br"></div>
			</template>
			<template
				v-slot:name-confirm
			>{{ $language.common.delete }}
			</template>

		</Popup_confirm>
	</div>
</template>

<script>
	import Empty from "@/view/Layout/Empty";
	import CartelVotingEditItem from "@/view/CartelVoting/CartelVotingEditItem";
	import draggable from 'vuedraggable'
	import PopupHalf from "@/view/Layout/PopupHalf";
	import Popup_confirm from "@/view/Layout/PopupConfirm";
	export default {
		name: 'CartelVotingEditItems'
		, props: ['user', 'edit_info']
		, components: {Popup_confirm, PopupHalf, CartelVotingEditItem, Empty, draggable}
		, data: function(){
			return {
				program: {
					title: this.$language.voting.title_voting_item_add
					, name: this.$language.voting.title_voting_item_add
					, type: 'cartel_sub'
					, not_header: true
					, not_footer: true
				}
				, items: []
				, item: {
					optiop_thumbnail_img: {
						optiop_img_partial_list: [

						]
					}
				}
				, is_on_item: false
				, is_action: ''
				, is_on_more: false
				, item_more: {}
				, title_half: this.$language.voting.title_voting_list
				, is_on_delete: false
				, item_vote: {

				}
			}
		}
		, computed: {
			is_next: function(){
				let t = true
				if(this.items.length > 1){
					t = false
				}
				return t
			}
			, cartl_vote_optiop_seqen_list: function(){
				let t = []
				this.items.filter( (item, index) => {
					t.push({
						cartl_vote_optiop_number: item.cartl_vote_optiop_number
						, sort_seqen: index + 1
					})
				})
				return t
			}
			, is_handle: function(){
				let t = true
				if(this.item_vote.cartl_vote_base_info?.cartl_vote_state_code == 'CA03700002'){
					t = false
				}
				return t
			}
		}
		, methods: {
			getData: async function(){
				try {
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_voting_bbs_items_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
							, cartl_vote_number: this.$route.params.e_id
						}
						, type: true
					})
					if (result.success) {
						this.items = result.data.cartl_vote_optiop_mangement_list
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, getVote: async function(){
				try {
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_voting_bbs_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
							, cartl_vote_number: this.$route.params.e_id
						}
						, type: true
					})
					if (result.success) {
						this.item_vote = result.data
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, toItem: function(item){
				if(item.cartl_vote_optiop_number){
					this.item = item
				}
				this.is_on_item = true
			}
			, prev: function(){
				this.$bus.$emit('to', { name: 'CartelVotingEdit', params: { idx: this.$route.params.idx, v_id: this.$route.params.v_id, e_id: this.$route.params.e_id}})
			}
			, postSort: async function(){
				try {
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_voting_items_sort
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
							, cartl_vote_number: this.$route.params.e_id
							, cartl_vote_optiop_seqen_list: this.cartl_vote_optiop_seqen_list
						}
						, type: true
					})
					if (result.success) {
						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
						return true
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
					return false
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, postTemp: async function(){
				if(await this.postSort()){
					await this.getData()
				}
			}
			, postSave: async function(){
				if(await this.postSort()){
					try {
						this.$bus.$emit('on', true)

						const result = await this.$Request({
							method: 'post'
							, url: this.$api_url.api_path.post_voting_bbs_items_temp
							, data: {
								member_number: this.user.member_number
								, cartl_number: this.$route.params.idx
								, cartl_vote_board_number: this.$route.params.v_id
								, cartl_vote_number: this.$route.params.e_id
								, cartl_vote_state_code: 'CA03700002'
								, cartl_vote_optiop_seqen_list: this.cartl_vote_optiop_seqen_list
							}
							, type: true
						})
						if (result.success) {
							this.$bus.$emit('to', { name: 'CartelVotingList', params: {idx: this.$route.params.idx, v_id: this.$route.params.v_id}})
						} else {
							throw result.message
						}
					} catch (e) {
						console.log(e)
						this.$bus.$emit('notify', { type: 'error', message: e})
						return false
					} finally {
						this.$bus.$emit('on', false)
					}
				}
			}
			, setAction: function ($e){
				console.log('e', $e)
				this.is_action = $e.oldIndex
			}
			, onMore: function(item){
				this.item_more = item
				this.is_on_more = true
			}
			, toEdit: function(){
				this.is_on_item = true
				this.item = this.item_more
				this.is_on_more = false
			}
			, offMore: function (){
				this.item_more = {}
				this.item = {}
				this.is_on_more = false
			}
			, onDelete: function(){
				this.is_on_delete = true
			}
			, offDelete: function(){
				this.item_more = {}
				this.is_on_delete = false
			}
			, postDelete: async function(){
				try {
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.delete_voting_bbs_item
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
							, cartl_vote_number: this.$route.params.e_id
							, cartl_vote_optiop_number: this.item_more.cartl_vote_optiop_number
						}
						, type: true
					})
					if (result.success) {
						await this.getData()
						this.offMore()
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.$bus.$emit('on', false)
					this.offDelete()
				}
			}
			, completeMore: function(){
				this.clearItem()
				this.getData()
			}
			, clearItem: function(){
				this.is_on_item = false
				this.item = {
					optiop_thumbnail_img: {
						optiop_img_partial_list: [

						]
					}
				}
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getVote()
			this.getData()

		}
	}
</script>